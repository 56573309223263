<template>
  <div class="wxposter-conatiner">
    <header class="wxposter-header">
      <van-nav-bar
          title="海报详情"
          safe-area-inset-top
      >
<!--        <template #right>-->
<!--          <icon-svg-->
<!--              iconClass="iconshare-2"-->
<!--              @click="handleShare"-->
<!--              style="height: 24px; width: 24px; color: '#333333'"-->
<!--          />-->
<!--        </template>-->
      </van-nav-bar>
    </header>
<!--    <article class="wxposter-details-article">-->
<!--      <img :src="baseUrl+posterDetais.posterImgUrl"/>-->
<!--    </article>-->
    <article class="poster-details-article" >
      <div class="poster-details" ref="imageWrapper">
        <img :src="`${baseUrl}${posterDetails.posterImgUrl}`" />
        <div class="text-qrcode">
          <div class="left-text">
            <p>{{ posterDetails.posterName }}</p>
            <p>· 扫一扫二维码,获取更多资讯 ·</p>
          </div>
          <img :src="posterDetails.qrCodeUrl"/>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { getPosterAppDetails} from "../../api/posterCenter"
import {reactive, toRefs} from "vue";
import {useRoute} from "vue-router";
export default {
  name: "wxPosterIndex",
  setup(){
    const route = useRoute()
    const  state = reactive({
      baseUrl:`${process.env.VUE_APP_WATCH_FILE_API}/`,
      posterId:route.params.id,
      posterDetails:{},
    })
    const loadPosterDetails = () =>{
      getPosterAppDetails(state.posterId).then((res)=>{
        console.log("微信分享",res)
        state.posterDetails = res
      })
    }
    loadPosterDetails()

    return{
      ...toRefs(state),
      loadPosterDetails,
    }
  }
}
</script>
<style lang="scss">
.wxposter-conatiner{
  .wxposter-header {
    font-size: 18px;
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
    .articleType-all {
      .van-tabs__wrap {
        border-bottom: 0.5px solid #f1f3f6;
      }
      .van-tab {
        color: #666666;
        font-size: 12px;
        font-weight: 600;
        flex: auto;
        //   border-bottom: 1px solid #cccccc;
      }
      .van-tab--active {
        color: #323233;
        font-size: 12px;
        font-weight: 600;
        width: auto;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wxposter-conatiner{
  display: flex;
  flex-direction: column;
  height: 100vh;
  .wxposter-details-article{
    flex: 1;
    overflow-x: hidden;
    img{
      display: block;
      width: 100%;
    }
  }

  .poster-details-article{
    padding-top: 10%;
    background-color: #E5E5E5;
    flex: 1;
    overflow-x: hidden;
    .poster-details{
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      >img{
        width: 300px;
        display: block;
        border-radius: 4px;
      }
      .text-qrcode{
        background-color: #ffff;
        display: flex;
        justify-content: space-between;
        padding: 18px;
        .left-text{
          text-align: left;
          line-height: 26px;
          p {
            margin: 0;
            padding: 0;
          }
          p:nth-child(1){
            font-size: 18px;
            font-weight: 600;
          }
          p:nth-child(2){
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            //line-height: 20px;
          }
        }
        img{
          display: block;
          width: 75px;
          height: 75px;
        }
      }

    }
    .handle-poster{
      font-size: 14px;
      font-weight: 500;
      color: #FC9834;
      display: flex;
      justify-content: space-evenly;
      span{
        margin-top: 16px;
        margin-bottom: 26px;
      }
    }
  }
}
</style>