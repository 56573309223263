


import {post, get, del, put} from '../../utils/request'

/**
 * 获取海报分类
 */
export function getPosterType(){
    return get(
        '/poster/getPosterType',
    )
}
/**
 * 获取海报id
 */
export function findNextId(){
    return get(
        '/poster/findNextId',
    )
}
/**
 * 获取海报
 */
export function getPosterListApp(params){
    return get(
        '/poster/getPosterListApp',
        params
    )
}
/**
 * 获取海报
 */
export function getMyPosterListApp(params){
    return get(
        '/poster/getMyPosterListApp',
        params
    )
}
/**
 * 根据id获取海报地址
 */
export function getPosterAppDetails(posterId){
    return get(
        `/poster/getPosterApp/${posterId}`,
    )
}
/**
 * 删除海报
 */
export function deletePosterApp(posterId){
    return del(
        `/poster/deletePosterApp/${posterId}`,
    )
}
/**
 * 创建海报
 */
export function createPosterApp(params){
    return post(
        '/poster/createPosterApp',
        params
    )
}
/**
 * 更新海报
 */
export function updatePosterApp(params){
    return put(
        '/poster/updatePosterApp',
        params
    )
}
/**
 * 分享海报
 */
export function reprintPoster(posterId){
    return post(
        `/poster/reprint/${posterId}`,
    )
}

